<template>
  <div>
    <div
      :class="{
        'outer-info': true,
        'playback-only': isOnlyPlayBack,
        'min-outer': UnitInfo && !!UnitInfo.message_time,
        'max-outer': UnitInfo && !UnitInfo.message_time,
        'payer-added': $route.name == 'unit-history',
        'sidebar-close': !sidebarToggle,
        'sidebar-open': !!sidebarToggle
      }"
      v-if="isShowMenu"
    >
      <button
        type="button"
        aria-label="Close"
        @click="clearUnitInfo"
        class="close"
      >
        &times;
      </button>
      <div
        class="unit-info-card"
        :class="{ 'history-card': $route.name == 'unit-history' }"
        v-if="unitInfoLoader && !UnitInfo"
      >
        <b-row style="width: 100%; max-height: 80%">
          <b-col md="4" sm="12" class="mt-1">
            <b-skeleton-img></b-skeleton-img>
          </b-col>
          <b-col md="4" sm="12" class="mt-1">
            <b-skeleton-img></b-skeleton-img>
          </b-col>
          <b-col md="4" sm="12" class="mt-1">
            <b-skeleton-img></b-skeleton-img>
          </b-col>
          <b-col md="2" sm="12" class="mt-1">
            <b-skeleton-img></b-skeleton-img>
          </b-col>
          <b-col md="2" sm="12" class="mt-1">
            <b-skeleton-img></b-skeleton-img>
          </b-col>
          <b-col md="2" sm="12" class="mt-1">
            <b-skeleton-img></b-skeleton-img>
          </b-col>
          <b-col md="2" sm="12" class="mt-1">
            <b-skeleton-img></b-skeleton-img>
          </b-col>
          <b-col md="2" sm="12" class="mt-1">
            <b-skeleton-img></b-skeleton-img>
          </b-col>
          <b-col md="2" sm="12" class="mt-1">
            <b-skeleton-img></b-skeleton-img>
          </b-col>
        </b-row>
      </div>
      <div
        class="unit-info-main"
        v-if="
          $route.name === 'unit-history' &&
          UnitInfo &&
          UnitInfo.name &&
          !unitInfoLoader
        "
      >
        <UnitPlayBack
          v-if="UnitInfo"
          :UnitInfo="UnitInfo"
          :isLiveSlide="isLiveSlide"
          :playback="playback"
          :isOnlyPlayBack="isOnlyPlayBack"
          :startPlayback="startPlayback"
        ></UnitPlayBack>
      </div>

      <div
        class="unit-info-card new-wall-comp"
        :class="{
          'history-card': $route.name == 'unit-history'
        }"
        v-if="UnitInfo && UnitInfo.name && !unitInfoLoader && !isOnlyPlayBack"
      >
        <b-card no-body class="cust-card-unit fst-card">
          <div class="card-data-ev">
            <b-avatar
              variant="light-primary"
              pills
              size="65"
              :src="UnitInfo.icon"
            />
            <div class="unit-name-ev-block">
              <div class="unit-name-ev-text align-account-name">
                {{ UnitInfo.name
                }}<span class="unit-allocated-name text-primary">
                  ({{
                    UnitInfo.allotted_account && UnitInfo.allotted_account.name
                  }})</span
                >
              </div>
              <Badge
                :tags="UnitInfo.devices"
                :showTextInBadge="Number(8)"
                :event="{ id: UnitInfo.name }"
                :maxTagShow="Number(2)"
                variant="light-primary"
              ></Badge>
            </div>
            <b-button
              variant="outline-primary"
              v-if="userModifiedPosition"
              @click="recenterUnit"
              v-b-tooltip.hover.top="{
                title: $t('unit.Recenter'),
                customClass: 'tooltip-card'
              }"
              class="btn-icon rounded-circle track-btn curserPointer"
            >
              <feather-icon icon="Navigation2Icon" />
            </b-button>
          </div>
          <div class="allocate-info top-15-px"></div>
          <div class="card-info-status">
            <div class="address-block">
              <AddressIcon class="address-icon" />

              <div class="address-txt text-primary" v-if="UnitInfo.position">
                <b-link
                  :href="`http://maps.google.com/maps?q=loc:${UnitInfo.position.latitude},${UnitInfo.position.longitude}`"
                  target="_blank"
                  >{{ UnitInfo.position && UnitInfo.position.address }}</b-link
                >
              </div>
            </div>
            <span
              v-if="UnitInfo.position && UnitInfo.position.device_name"
              class="info-address-tool curserPointer"
              v-b-tooltip.html.top="{
                title: `
          <b class='no-wp-txt'>${$t(
            'UnitTrack.ReportedBy'
          )}:</b><span class='no-wp-txt'> ${
                  UnitInfo.position.device_name
                }  </span>
          <b class='no-wp-txt'>${$t(
            'UnitTrack.ReportedAt'
          )}:</b><span class='no-wp-txt'> ${getFormattedTime(
                  UnitInfo.message_time
                )}  </span>`,
                customClass: 'tooltip-card'
              }"
            >
              <feather-icon class="text-primary" icon="InfoIcon" size="15" />
            </span>
          </div>
        </b-card>
        <b-card
          no-body
          class="cust-card-unit second-card"
          v-if="UnitInfo.operator && UnitInfo.operator.name"
        >
          <div class="card-data-ev">
            <b-avatar
              variant="light-primary"
              pills
              size="60"
              :src="UnitInfo.operator && UnitInfo.operator.icon"
            />
            <div class="unit-name-ev-block">
              <div class="unit-name-ev-text">
                {{
                  UnitInfo.operator && UnitInfo.operator.name
                    ? UnitInfo.operator.name
                    : $t("unit.UnsignedDriver")
                }}
              </div>
              <div
                class="allow-text text-primary mt-10-mail"
                v-show="UnitInfo.operator && UnitInfo.operator.email"
              >
                <b-link
                  :href="`mailto:${UnitInfo.operator.email}`"
                  target="_blank"
                >
                  <MailIcon class="call-icon" /><span class="ml-10">{{
                    UnitInfo.operator.email
                  }}</span>
                </b-link>
              </div>
            </div>
          </div>
          <div class="allocate-info top-15-px"></div>
          <div class="allocate-info wrap-allow-info">
            <div
              class="allow-text shift-time mt-10-mail"
              v-show="
                UnitInfo &&
                UnitInfo.operator &&
                UnitInfo.operator.shift_start_time
              "
            >
              <div>
                <ShiftIcon class="shift-icon" />
                <span class="shift-txt ml-10"
                  >{{ $t("UnitTrack.Shift") }} {{ $t("UnitTrack.Time") }}
                </span>
              </div>
              <span class="bold-time"
                >{{
                  `${
                    UnitInfo.operator && UnitInfo.operator.shift_start_time
                      ? `${DateTimeConvert(
                          UnitInfo.operator.shift_start_time,
                          constants.DATE_TIME_FORMAT
                        )}`
                      : ""
                  }`
                }}
                {{
                  UnitInfo.operator && UnitInfo.operator.shift_end_time
                    ? $t("UnitTrack.To")
                    : ""
                }}
                {{
                  `${
                    UnitInfo.operator && UnitInfo.operator.shift_end_time
                      ? `${DateTimeConvert(
                          UnitInfo.operator.shift_end_time,
                          constants.DATE_TIME_FORMAT
                        )}`
                      : ""
                  }`
                }}</span
              >
            </div>

            <div
              class="allow-text shift-time mt-10-mail"
              v-show="UnitInfo.operator && UnitInfo.operator.mobile"
            >
              <div>
                <CallIcon class="call-icon" />
                <span class="shift-txt ml-10"
                  >{{ $t("UnitInfo.operator.ContactNo") }}:</span
                >
              </div>
              <b-link :href="`tel:${UnitInfo.operator.mobile}`" target="_blank">
                <span class="ml-10">{{ UnitInfo.operator.mobile }} </span>
              </b-link>
            </div>
          </div>
        </b-card>
        <template v-for="device in UnitInfo.devices">
          <span
            v-for="(data, index) in device.data"
            :key="index + device.id"
            :class="`${data.type ? data.type : ''} outer-span-blank`"
          >
            <span class="outer-span" v-if="data.display_card">
              <span class="inner-span" v-html="data.display_card"></span>
              <span
                v-if="
                  data.display_card &&
                  device.message_time &&
                  data.type != 'VIDEO_STREAM'
                "
                class="info-icon-overlay"
                v-b-tooltip.html.top="{
                  title: (title = `<b class='no-wp-txt'>${$t(
                    'UnitTrack.ReportedBy'
                  )}:</b><span class='no-wp-txt'> ${
                    device.name
                  }  </span> <b class='no-wp-txt'>${$t(
                    'UnitTrack.ReportedAt'
                  )}:</b><span class='no-wp-txt'> ${getFormattedTime(
                    device.message_time
                  )}  </span>`),
                  customClass: 'tooltip-card'
                }"
              >
                <feather-icon class="text-primary" icon="InfoIcon" size="15" />
              </span>

              <button
                class="on-card-video live-btn"
                :style="
                  !['unit-history', 'unit-map'].includes($route.name)
                    ? 'pointer-events: none !important'
                    : ''
                "
                v-if="data.data.is_live || data.data.camera_type === 0"
                @click="openVideoStream(data, device)"
              >
                {{ $t("unit.Live") }} <span class="live-doth"></span>
              </button>

              <button
                :style="
                  !['unit-history', 'unit-map'].includes($route.name)
                    ? 'pointer-events: none !important'
                    : ''
                "
                class="on-card-video live-btn"
                v-if="
                  !data.data.is_live &&
                  data.type === 'VIDEO_STREAM' &&
                  data.data.camera_type !== 0
                "
                @click="openVideoStream(data, device)"
              >
                {{ $t("unit.Start") }} <span class="live-doth"></span>
              </button>
            </span>
          </span>
        </template>
      </div>

      <div
        class="last-sync"
        v-if="
          UnitInfo &&
          UnitInfo.message_time &&
          !unitInfoLoader &&
          !isOnlyPlayBack
        "
      >
        <LastSync />
        <span class="ls-sync" v-if="$route.name === 'unit-map'">
          {{ $t("unit.LastSync") }} :</span
        >

        <span
          class="text-primary"
          v-b-tooltip.hover.top="{
            title: DateTimeConvert(
              UnitInfo.message_time,
              constants.DATE_TIME_FORMAT
            ),
            customClass: 'my-tooltip-class tooltip-card'
          }"
          >{{ getFormattedTime(UnitInfo.message_time) }}</span
        >
      </div>
    </div>
    <b-modal
      id="open-stream-popup"
      centered
      :hide-header="true"
      no-close-on-esc
      no-close-on-backdrop
      modal-class="no-header-modal player-modal"
    >
      <VideoStream
        :options="options"
        :name="sensorName"
        :unitObj="unitObj"
        :deviceObj="deviceObj"
        :source="source"
        :getLiveBtnStatus="getLiveBtnStatus"
        @closeVideoModal="closeVideoModal"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  VBTooltip,
  BModal,
  BButton,
  BLink,
  BCol,
  BRow,
  BSkeleton,
  BSkeletonImg
} from "bootstrap-vue";
import Badge from "@/layouts/components/Badge.vue";
import CallIcon from "@/assets/images/unit-icon/call.svg";
import MailIcon from "@/assets/images/unit-icon/mail.svg";
import ShiftIcon from "@/assets/images/unit-icon/shift.svg";
import LastSync from "@/assets/images/unit-icon/last-sync-icon.svg";
import VideoStream from "./videoStream.vue";
import AddressIcon from "@/assets/images/unit-icon/address-icon.svg";
import UnitPlayBack from "./UnitPlayBack";
import store from "@/store";
import constants from "@/utils/constants";
export default {
  components: {
    BAvatar,
    BCard,
    Badge,
    VBTooltip,
    CallIcon,
    AddressIcon,
    VideoStream,
    BModal,
    BLink,
    MailIcon,
    ShiftIcon,
    BButton,
    BCol,
    BRow,
    BSkeleton,
    BSkeletonImg,
    UnitPlayBack,
    LastSync
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      liveVideo: null,
      options: null,
      sensorName: "",
      unitObj: null,
      deviceObj: null,
      source: "",
      isOnlyPlayBack: this.$route.name === "unit-history" ? true : false,
      isShowMenu: true
    };
  },
  props: [
    "UnitInfo",
    "clearUnitInfo",
    "userModifiedPosition",
    "onUserModifiedPosition",
    "unitInfoLoader",
    "playback",
    "activeMenu",
    "onInfoMenuCloseMainModal",
    "isLiveSlide",
    "sidebarToggle"
  ],
  watch: {},
  mounted() {
    this.isShowMenu = true;
  },
  computed: {},
  methods: {
    closeVideoModal() {
      this.$bvModal.hide("open-stream-popup");
      this.isShowMenu = true;
      this.onInfoMenuCloseMainModal(false);
      store.commit("mapLoader/SET_IS_POPUP_SHOW", false);
    },
    recenterUnit() {
      this.$emit("onRecenterUnit");
    },
    DateTimeConvert(date, format) {
      return this.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    },
    startPlayback(isPlayTrackStart) {
      if (isPlayTrackStart) {
        this.isOnlyPlayBack = true;
      } else {
        this.isOnlyPlayBack = !this.isOnlyPlayBack;
        if (!this.isOnlyPlayBack) {
          this.playback.pause();
        }
      }
    },
    getFormattedTime(time) {
      return this.updateReportTime(time);
    },
    openVideoStream(data, device) {
      this.liveVideo = data;
      this.sensorName = data && data.sensor_name;
      this.options = {
        isLiveMode: data && data.data && data.data.is_live
      };
      this.source = data && data.data && data.data.source;
      this.unitObj = { id: this.UnitInfo.id };
      this.deviceObj = {
        id: device.id
      };
      if (data.data && data.data.camera_type !== undefined) {
        this.deviceObj.camera_type = data.data.camera_type;
      }
      if (data.data && data.data.clipping_time !== undefined) {
        this.deviceObj.clipping_time = data.data.clipping_time;
      }
      // this.isOnlyPlayBack = true;
      this.$bvModal.show("open-stream-popup");
      this.onInfoMenuCloseMainModal(true);
      store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
      this.isShowMenu = false;
    },
    getLiveBtnStatus(e) {
      this.UnitInfo.devices.forEach((device, index) => {
        if (device && device.id === e.id) {
          device.data.forEach((sensor, index1) => {
            if (sensor.name === e.name) {
              this.UnitInfo.devices[index].data[index1].data.is_live = !e.stop;
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="scss">
.outer-span {
  position: relative;
  .info-icon-overlay {
    position: absolute;
    top: 15px;
    right: 30px;
    height: 20px;
    cursor: pointer;
  }
}

.outer-span,
.inner-span {
  display: inline-block;
  position: relative;
  width: 100%;
}

.outer-info .close {
  position: absolute;
  right: -10px;
  top: -10px;
  height: 30px;
  width: 30px;
  background-color: var(--white) !important;
  box-shadow: 0px 0px 15px 0px rgba(34, 41, 47, 0.05);
  z-index: 999999;
  padding: 0;
  border-radius: 6px;
  opacity: 1;
}
.dark-layout {
  .unit-info-card {
    background-color: var(--unit-bg-dark);
    .cust-card-unit {
      .unit-name-ev-block {
        .unit-name-ev-text {
          color: var(--light);
        }
      }
    }
    .shift-time {
      color: var(--light);
    }
  }
  .outer-info .close {
    background-color: var(--dark46) !important;
    color: var(--white);
  }
}

.dark-layout {
  .inner-span {
    .event-card {
      background-color: var(--dark46);
    }
  }
  .outer-span {
    .info-icon-overlay {
      background-color: var(--dark46);
    }
  }
}

.no-wp-txt {
  white-space: nowrap;
  font-size: 11px;
}
.VIDEO_STREAM .live-btn {
  border-radius: 4px;
  border: 1px solid #ed0000;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 10px 1px #e70606;
  color: #ed0000;
  position: absolute;
  margin: auto;
  top: 88px;
  left: calc(50% - 50px);
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  z-index: 9999;
}
.VIDEO_STREAM .live-doth {
  background-color: #ed0000;
  height: 12px;
  width: 12px;
  border-radius: 12px;
  display: inline-block;
  margin-left: 8px;
}
.player-modal {
  .modal-dialog {
    min-width: 580px;
    .overlay-btn {
      min-height: 350px;
    }
  }
  .modal-body {
    padding: 0 5px 5px 5px;
    .bottom-video-name-modal {
      display: flex;
      align-items: center;
      margin-top: 15px;
      margin-bottom: 15px;
      padding-left: 15px;
      .channel-name {
        padding-left: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 230px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .player-modal .modal-dialog {
    min-width: 96%;
  }
}
.fst-card {
  position: relative;
  .unit-name-ev-block {
    max-width: calc(100% - 65px);
  }
}
.track-btn {
  position: absolute;
  top: 8px;
  right: 10px;
}
.info-address-tool {
  position: absolute;
  bottom: 5px;
  right: 10px;
}
.mt-1 .b-aspect {
  max-height: 133px;
}
.max-w {
  max-width: none !important;
}
.bottom-25 {
  margin-bottom: -25px;
}
@media screen and (max-width: 667px) {
  .outer-info.min-outer {
    height: calc(100% - 30px) !important;
    margin-top: 100px !important;
    right: 0;
  }
  .outer-info.min-outer.playback-only {
    height: 140px !important;
    top: auto !important;
  }
  .history-card {
    max-height: 58vh !important;
  }
}
.close-video-modal {
  display: flex;
  justify-content: end;
  padding: 10px 10px 0px 0px;
}
@media only screen and (min-width: 700px) {
  .unit-page-outer {
    .outer-info:after {
      display: none !important;
    }
  }
}
/*rl206 unnit info*/
@media only screen and (min-width: 700px) {
  .unit-page-outer {
    .outer-info:after {
      display: none;
    }
  }
}
</style>
